import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './DataProcessingAgreement.module.css';

import Container from '../../../blocks/Container';

const DataProcessingAgreement = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/dpa.md/" } }) {
        edges {
          node {
            html
          }
        }
      }
    }
  `);

  const { html } = data.allMarkdownRemark.edges[0].node;

  return (
    <div className={styles.dataProcessingAgreement}>
      <Container type="big">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </div>
  )
};

export default DataProcessingAgreement;