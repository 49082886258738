import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import DataProcessingAgreement from '../components/slides/dpa/DataProcessingAgreement';

const Dpa = () => {
  return (
    <Layout>
      <SEO
        title="Data Processing Agreement | Deadline Funnel"
        description="Learn how Deadline Funnel protects your privacy."
      />
      <DataProcessingAgreement />
    </Layout>
  )
};

export default Dpa;